'use client'

import clsx from 'clsx'

import { internalLink, usePathname } from '~/lib/navigation'

interface Props extends React.ComponentProps<'div'> {}

export const HeaderInner: React.FC<Props> = ({
  children,
  className,
  ...props
}) => {
  const pathname = usePathname()
  const secondary = pathname === internalLink.index

  return (
    <div
      className={clsx('flex items-center', {
        'text-white/90': secondary,
        className,
      })}
      {...props}
    >
      {children}
    </div>
  )
}
